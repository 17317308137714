import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { generateFullName } from 'utils/generateFullNameUtils';
import { Tooltip } from 'primereact/tooltip';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { prioritySortList } from 'constants/dropdownOptions';

export default function Filter(props) {
  const { practiceAreaList, userList, refreshList, filter, filterOnChange } = props;
  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i class="fas fa-user-tie me-2"></i>}
        {generateFullName(option)}
      </>
    );
  };

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-start p-2 p-lg-0 filter-wrap lead-filter autowidth">
      <div className="p-2 autowidth">
        <span className="p-input-icon-left input-shadow autowidth">
          <i className="icon-search F-size16" />
          <TextSnippetWrapper
            type="text"
            placeholder="Search By Client"
            name="keyword"
            value={filter?.keyword || ''}
            onChange={filterOnChange}
            className="p-inputtext p-component input-search"
          />
        </span>
      </div>
      <div className="d-flex align-items-center filter-wraper p-2">
        <span className="me-2 text-bold black-600  filter-label py-0">Filters: </span>
        <div className="d-flex flex-column me-2 lead-filter py-0">
          <MultiSelect
            name="assigned_to"
            value={filter?.assigned_to || ''}
            onChange={filterOnChange}
            options={userList ?? []}
            optionLabel={(e) => generateFullName(e)}
            isSearchable
            placeholder="Select Assignee"
            className="w-100 input-shadow input-width input-height"
            optionValue="user_id"
            filter
            filterPlaceholder="Search"
            resetFilterOnHide
            itemTemplate={assignToItemTemplate}
          />
        </div>
        <div className="d-flex flex-column me-2 lead-filter py-0">
          <Dropdown
            name="practice_area"
            value={filter.practice_area || ''}
            placeholder="Select Practice Area"
            options={practiceAreaList ?? []}
            filter
            filterPlaceholder="Search"
            optionLabel="practice_area_name"
            optionValue="practice_area_name"
            className="w-100 input-shadow input-width input-height"
            onChange={filterOnChange}
            resetFilterOnHide
          />
        </div>
        <div className="d-flex flex-column me-2 lead-filter py-0">
          <Dropdown
            name="priority"
            value={filter.priority || ''}
            placeholder="Sort by Priority"
            options={prioritySortList}
            optionLabel="label"
            optionValue="value"
            className="w-100 input-shadow input-width input-height"
            onChange={filterOnChange}
            resetFilterOnHide
          />
        </div>
        <div className="d-flex pointer align-items-center p-2">
          <i className="fas fa-sync-alt" onClick={refreshList} />
          <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
        </div>
      </div>
    </div>
  );
}
